.content {
	position: relative;
	opacity: 0;
	font-size: 1rem;
	text-align: left;
	font-family: Verdana;
	max-width: 640px;
	display: block;
	margin: auto;
	padding: 1em;
	transition: opacity 600ms, transform 600ms;
	transform: translate3d(-20px, 0, 0);
	transition-delay: 300ms;
	background-color: rgba(0, 0, 0, 0.7);
	overflow: visible;
	transition-timing-function: ease-in-out;
}
.inviewFade {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}
