.wrapper {
	max-width: 600px;
	margin: auto;
	margin-top: 3em;
	background-color: rgba(0, 0, 0, 0.7);
	composes: fadeIn from "styles/animations.module.css";
}

.headingWrap {
	background-size: cover;
	background-position: center;
	overflow: hidden;
	border-bottom: 2px solid black;
}
.headingWrapInner {
	padding: 2em 1em;
}
@keyframes headerAnim {
	from {
		opacity: 0;
		transform: translateX(-100%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
.header,
.subHeader {
	animation-name: headerAnim;
	animation-duration: 500ms;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	color: #fff;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.8), 1px -1px 0px rgba(0, 0, 0, 0.7),
		-1px 1px 0px rgba(0, 0, 0, 0.7), -1px -1px 0px rgba(0, 0, 0, 0.6);
	letter-spacing: 1.4px;
}
.header {
	font-size: 2rem;
	margin-top: 1em;
	margin-bottom: 0;
}
.subHeader {
	animation-delay: 200ms;
	margin-top: 0;
}

@keyframes contentAnim {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.content {
	padding: 0 1em 1em;
	animation-name: contentAnim;
	animation-duration: 1s;
	animation-delay: 600ms;
	animation-fill-mode: both;
	animation-iteration-count: 1;
}

.content p {
	margin-bottom: 1.5em;
}

.content img {
	width: 100%;
}
.content audio {
	width: 100%;
}
.sequence {
	display: flex;
	justify-content: space-around;
	max-width: 480px;
	margin: auto;
}

.prevNextHeaders {
	margin: 0;
}

.next {
	text-align: right;
}

.previous,
.next {
	margin: 1em;
	background-color: rgba(0, 0, 0, 0.2);
	width: 100%;
	padding: 1em;
	display: flex;
	justify-content: space-between;
}
.arrow {
	vertical-align: middle;
}

.categories {
	border-top: 1px dashed rgba(0, 0, 0, 0.4);
	padding: 0.5em;
	display: flex;
	justify-content: flex-end;
}
.category {
	padding: 0.5em;
	text-shadow: 1px 1px black;
}
.category:nth-child(7n + 1) {
	color: red;
}
.category:nth-child(7n + 2) {
	color: orange;
}
.category:nth-child(7n + 3) {
	color: yellow;
}
.category:nth-child(7n + 4) {
	color: green;
}
.category:nth-child(7n + 5) {
	color: blue;
}
.category:nth-child(7n + 6) {
	color: indigo;
}
.category:nth-child(7n + 7) {
	color: violet;
}
