.notationsContainer {
	perspective: 600px;
	min-height: 100vh;
}
.great {
	height: 25vw;
	max-height: 160px;
	position: relative;
	z-index: 2;
	pointer-events: none;
	mix-blend-mode: difference;
}
.great > * {
	height: 100%;
	margin-top: -12.5%;
}

.great > *:before {
	left: 0;
}
.great > *:after {
	right: 0;
	transform: scaleX(-1);
}
.great > *:before,
.great > *:after {
	display: inline-block;
	content: '';
	top: 0;
	background-image: url('./great.png');
	background-size: contain;
	background-repeat: no-repeat;
	height: 100%;
	width: 50%;
}
