@keyframes background-gradient {
	0% {
		background-position: 0% 11%;
	}
	50% {
		background-position: 100% 90%;
	}
	100% {
		background-position: 0% 11%;
	}
}

.gradientAnim {
	background-image: linear-gradient(135deg, #fccf31 10%, #f55555 100%);
	background-size: 400% 400%;
	padding-top: 20px;
	animation: background-gradient 12s ease infinite;
}

.homepage {
	composes: fontMain from '../../styles/fonts.css';
	text-align: center;
	min-height: 100vh;
}

.logoWrapper {
	height: 20vh;
	width: 20vh;
	font-size: 0;
	border-radius: 999px;
	border: 1px dashed black;
	overflow: hidden;
	display: inline-block;
}
.AppLogo {
	width: 100%;
	height: 100%;
	border-radius: 999px;
}
@keyframes loaded {
	from {
		transform: rotate(-7deg);
		opacity: 0;
	}
	to {
		transform: rotate(0deg);
		opacity: 1;
	}
}
.loaded {
	animation-name: loaded;
	animation-duration: 400ms;
	animation-delay: 200ms;
	animation-timing-function: ease;
	animation-iteration-count: 1;
	animation-fill-mode: both;
}

.AppTitleSubheading {
	margin-top: 0;
	margin-bottom: 20vh;
	text-shadow: 1px 1px black;
	font-size: 24px;
}

.AppTitle {
	font-size: 190px;
	opacity: 0.9;
	margin: 0;
	text-shadow: 2px 2px black;
}
.hello {
	font-size: 120px;
}

@media screen and (max-width: 800px) {
	.AppTitle {
		font-size: 100px;
	}
}
.contentWrap {
	max-width: 960px;
	margin: auto;
}
/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
	.AppTitle {
		font-size: 90px;
	}
}
.AppTitle,
.AppTitleSubheading {
	mix-blend-mode: multiply;
	color: aquamarine;
	line-height: 1em;
}

.AppIntro {
	font-size: large;
	font-family: Verdana;
	color: aqua;
	mix-blend-mode: multiply;
	text-shadow: 1px 1px black;
	opacity: 0.8;
}

.TitleLetter {
	composes: fontMain from '../../styles/fonts.css';
	margin-right: 0.2em;
	font-size: 1.75em;
	height: 0.5em;
	display: inline-block;
}
@keyframes wobble {
	from {
		transform: scaleX(0);
	}
	to {
		transform: scaleX(1);
	}
}
.inviewWobble {
	transform-origin: left;
	animation-name: wobble;
	animation-duration: 1s;
	animation-iteration-count: 1;
}
.recentFooter {
	margin: 2px -3px;
}
