.loading {
	display: flex;
	justify-content: center;
	padding: 1em;
}

.fullpage {
	z-index: 1000;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	composes: fadeIn from "styles/animations.module.css";
}
