.container {
	font-size: 1.5rem;
	height: 62px;
	text-align: center;
	font-family: 'Pacifico', cursive;
}

.floating,
.notFloating {
	width: 100%;
	background-color: rgb(33, 148, 122);
	padding: 0.5em;
}

.floating {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 4;
}
.notFloating {
}
.floatingInner {
	display: inline-block;
	margin: auto;
	min-width: 320px;
}

.floatingInnerInner {
	display: flex;
	justify-content: space-around;
}
