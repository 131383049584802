.top {
	border-top: 3px solid rgba(85, 85, 85, 0.58);
}

.funkyCorners {
	position: relative;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
}

.funkyCorners:before {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	background-color: wheat;
	height: 1em;
	width: 1em;
	border-bottom-right-radius: 1em;
}

.funkyCorners:after {
	content: ' ';
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: wheat;
	height: 1em;
	width: 1em;
	border-top-left-radius: 1em;
}

.bottomShiny{
	position: relative;
}

.bottomShiny:before {
	content: ' ';
	position: absolute;
	background: linear-gradient(to left, #f55555, #9708CC);
	bottom: 0;
	height: 4px;
	left: 2px;
	right: 2px;
	border-bottom-left-radius: 20px;
	border-top-right-radius: 20px;
	z-index: -1;
}