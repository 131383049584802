
.facts {
	font-weight: bold;
	text-shadow: black 1px 1px 1px; 
	text-transform: uppercase;
	position: relative;
	/* text-align: center; */
}

.factsRainbowBar {
	position:absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	height: 2px;
}

@keyframes factsrainbowscroll {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-50%);
	}
}

.factsRainbowBarInner {
	width: 200%;
	height: 100%;
	animation-name: factsrainbowscroll;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	background: linear-gradient(to right, rgba(255,0,0), rgba(0,255,0), rgba(0,0,255), rgba(255,0,0));
	background-size: 50%;
}
