@keyframes pagey {
	from {
		opacity: 0;
		/*transform: translate(0, -100vh);*/
	}
	to {
		opacity: 1;
		/*transform: translate(0, 0vh);*/
	}
}

.main {
	/*animation-name: pagey;*/
	/*animation-duration: .5s;*/
	/*animation-iteration-count: 1;*/

	/*perspective: 200px;*/
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding-bottom: 8vh;


}

.main > * {
	height: 100%;
	width: 100%;
	overflow: auto;
}
