body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background-color: #f7a825;
	position: relative;
	overflow-x: hidden;
}

a {
	text-decoration: none;
	color: inherit;
}

#root {
	overflow-x: hidden;
}
