.button {
	padding-left: 0.25em;
	padding-right: 0.25em;
	transition: opacity 0.3s;
	opacity: 0.8;
	cursor: pointer;
	text-decoration: none;
	position: relative;
	composes: bottomShiny from '../../styles/borders.css';
}
.button:hover {
	opacity: 1;
	text-shadow: 1px 1px black;
}
.button:visited {
	color: inherit;
}
.button:link {
	color: inherit;
}
.button:before {
	transition: opacity 0.5s;
	opacity: 0.3;
}
.button:hover:before {
	opacity: 0;
}
