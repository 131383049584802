@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.fadeIn {
	animation-name: fade-in;
	animation-duration: 900ms;
	animation-fill-mode: both;
	animation-iteration-count: 1;
}
