body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background-color: #f7a825;
	position: relative;
	overflow-x: hidden;
}

a {
	text-decoration: none;
	color: inherit;
}

#root {
	overflow-x: hidden;
}

/* body {
	background-color: white;
} */

.App_App__2uGl8 {
	color: white;
	position: relative;
	padding-bottom: 10em;

	z-index: 3;
	position: relative;
	min-height: 100vh;
}
.App_footer__3BnF- {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.1);
	border-top: 1px dashed rgba(0, 0, 0, 0.2);
	color: rgba(255, 255, 255, 0.4);
	padding: 2em;
	display: flex;
	justify-content: space-around;
}
.App_footer__3BnF- > * {
	font-size: 10px;
}
.App_sea__3GnJx {
	z-index: -2;
	position: absolute;
	background: linear-gradient(transparent, #e66465, #9198e5);
	left: 0;
	right: 0;
	bottom: 0;
	height: 50vh;
}
.App_love__IJqOk {
	width: 10em;
	padding: 1em;
	text-align: center;
}

@-webkit-keyframes App_cloud__2YVtA {
	from {
		-webkit-transform: translateX(-2vw);
		        transform: translateX(-2vw);
	}
	to {
		-webkit-transform: translateX(2vw);
		        transform: translateX(2vw);
	}
}

@keyframes App_cloud__2YVtA {
	from {
		-webkit-transform: translateX(-2vw);
		        transform: translateX(-2vw);
	}
	to {
		-webkit-transform: translateX(2vw);
		        transform: translateX(2vw);
	}
}

.App_cloud__2YVtA {
	-webkit-animation-name: App_cloud__2YVtA;
	        animation-name: App_cloud__2YVtA;
	-webkit-animation-duration: 9s;
	        animation-duration: 9s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	z-index: 2;
	position: fixed;
	left: 5vw;
	top: 13vh;
}

.App_blocks__mFtF8 {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 246px;
	background-image: url(https://static.foxxo.co.uk/808112b417385b2f30a408c2fa2149a5a00894c5/media/blox.bf8da0a7.png);
	background-size: 831px;
	z-index: -1;
	background-repeat-x: repeat;
	bottom: 0;
	background-position-x: center;
}

@-webkit-keyframes pagey {
	from {
		opacity: 0;
		/*transform: translate(0, -100vh);*/
	}
	to {
		opacity: 1;
		/*transform: translate(0, 0vh);*/
	}
}

@keyframes pagey {
	from {
		opacity: 0;
		/*transform: translate(0, -100vh);*/
	}
	to {
		opacity: 1;
		/*transform: translate(0, 0vh);*/
	}
}

.main {
	/*animation-name: pagey;*/
	/*animation-duration: .5s;*/
	/*animation-iteration-count: 1;*/

	/*perspective: 200px;*/
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding-bottom: 8vh;


}

.main > * {
	height: 100%;
	width: 100%;
	overflow: auto;
}

@-webkit-keyframes animations_fade-in__1iqqt {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes animations_fade-in__1iqqt {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.animations_fadeIn__2xk0P {
	-webkit-animation-name: animations_fade-in__1iqqt;
	        animation-name: animations_fade-in__1iqqt;
	-webkit-animation-duration: 900ms;
	        animation-duration: 900ms;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
}

.Loading_loading__3gFIZ {
	display: flex;
	justify-content: center;
	padding: 1em;
}

.Loading_fullpage__12R1o {
	z-index: 1000;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
}

.styles_wrapper__20kSL {
	max-width: 600px;
	margin: auto;
	margin-top: 3em;
	background-color: rgba(0, 0, 0, 0.7);
}

.styles_headingWrap__2KLRS {
	background-size: cover;
	background-position: center;
	overflow: hidden;
	border-bottom: 2px solid black;
}
.styles_headingWrapInner__2mJxK {
	padding: 2em 1em;
}
@-webkit-keyframes styles_headerAnim__djkQe {
	from {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		        transform: translateX(-100%);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}
@keyframes styles_headerAnim__djkQe {
	from {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		        transform: translateX(-100%);
	}
	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}
.styles_header__1X1VI,
.styles_subHeader__2FUYt {
	-webkit-animation-name: styles_headerAnim__djkQe;
	        animation-name: styles_headerAnim__djkQe;
	-webkit-animation-duration: 500ms;
	        animation-duration: 500ms;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	color: #fff;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.8), 1px -1px 0px rgba(0, 0, 0, 0.7),
		-1px 1px 0px rgba(0, 0, 0, 0.7), -1px -1px 0px rgba(0, 0, 0, 0.6);
	letter-spacing: 1.4px;
}
.styles_header__1X1VI {
	font-size: 2rem;
	margin-top: 1em;
	margin-bottom: 0;
}
.styles_subHeader__2FUYt {
	-webkit-animation-delay: 200ms;
	        animation-delay: 200ms;
	margin-top: 0;
}

@-webkit-keyframes styles_contentAnim__1HG3w {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes styles_contentAnim__1HG3w {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.styles_content__1aFX9 {
	padding: 0 1em 1em;
	-webkit-animation-name: styles_contentAnim__1HG3w;
	        animation-name: styles_contentAnim__1HG3w;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-delay: 600ms;
	        animation-delay: 600ms;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
}

.styles_content__1aFX9 p {
	margin-bottom: 1.5em;
}

.styles_content__1aFX9 img {
	width: 100%;
}
.styles_content__1aFX9 audio {
	width: 100%;
}
.styles_sequence__2YfVP {
	display: flex;
	justify-content: space-around;
	max-width: 480px;
	margin: auto;
}

.styles_prevNextHeaders__2p1HT {
	margin: 0;
}

.styles_next__3LJdh {
	text-align: right;
}

.styles_previous__18aEM,
.styles_next__3LJdh {
	margin: 1em;
	background-color: rgba(0, 0, 0, 0.2);
	width: 100%;
	padding: 1em;
	display: flex;
	justify-content: space-between;
}
.styles_arrow__1LGLo {
	vertical-align: middle;
}

.styles_categories__2Rx4g {
	border-top: 1px dashed rgba(0, 0, 0, 0.4);
	padding: 0.5em;
	display: flex;
	justify-content: flex-end;
}
.styles_category__3EZam {
	padding: 0.5em;
	text-shadow: 1px 1px black;
}
.styles_category__3EZam:nth-child(7n + 1) {
	color: red;
}
.styles_category__3EZam:nth-child(7n + 2) {
	color: orange;
}
.styles_category__3EZam:nth-child(7n + 3) {
	color: yellow;
}
.styles_category__3EZam:nth-child(7n + 4) {
	color: green;
}
.styles_category__3EZam:nth-child(7n + 5) {
	color: blue;
}
.styles_category__3EZam:nth-child(7n + 6) {
	color: indigo;
}
.styles_category__3EZam:nth-child(7n + 7) {
	color: violet;
}

.styles_container__2qVoU {
	font-size: 1.5rem;
	height: 62px;
	text-align: center;
	font-family: 'Pacifico', cursive;
}

.styles_floating__PtNK3,
.styles_notFloating__3JZqy {
	width: 100%;
	background-color: rgb(33, 148, 122);
	padding: 0.5em;
}

.styles_floating__PtNK3 {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 4;
}
.styles_notFloating__3JZqy {
}
.styles_floatingInner__14srm {
	display: inline-block;
	margin: auto;
	min-width: 320px;
}

.styles_floatingInnerInner__sPn58 {
	display: flex;
	justify-content: space-around;
}

.borders_top__2UjZb {
	border-top: 3px solid rgba(85, 85, 85, 0.58);
}

.borders_funkyCorners__jXRJ2 {
	position: relative;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
}

.borders_funkyCorners__jXRJ2:before {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	background-color: wheat;
	height: 1em;
	width: 1em;
	border-bottom-right-radius: 1em;
}

.borders_funkyCorners__jXRJ2:after {
	content: ' ';
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: wheat;
	height: 1em;
	width: 1em;
	border-top-left-radius: 1em;
}

.borders_bottomShiny__2z5Tf{
	position: relative;
}

.borders_bottomShiny__2z5Tf:before {
	content: ' ';
	position: absolute;
	background: linear-gradient(to left, #f55555, #9708CC);
	bottom: 0;
	height: 4px;
	left: 2px;
	right: 2px;
	border-bottom-left-radius: 20px;
	border-top-right-radius: 20px;
	z-index: -1;
}
.button_button__1D5Yx {
	padding-left: 0.25em;
	padding-right: 0.25em;
	transition: opacity 0.3s;
	opacity: 0.8;
	cursor: pointer;
	text-decoration: none;
	position: relative;
}
.button_button__1D5Yx:hover {
	opacity: 1;
	text-shadow: 1px 1px black;
}
.button_button__1D5Yx:visited {
	color: inherit;
}
.button_button__1D5Yx:link {
	color: inherit;
}
.button_button__1D5Yx:before {
	transition: opacity 0.5s;
	opacity: 0.3;
}
.button_button__1D5Yx:hover:before {
	opacity: 0;
}

.styles_ribbon__1Z8I9 {
	z-index: 2;
	position: absolute;
	font-family: Verdana;
	font-size: .7rem;
	right:0;
	top: 0;
    -webkit-transform: translateX(33%) translateY(0.75em) rotate(45deg);
            transform: translateX(33%) translateY(0.75em) rotate(45deg);
	text-shadow: black 1px 1px;
}
.styles_ribbonInner__16cW1 {
	position: relative;
}
.styles_ribbonTailLeft__1xv3H {
	border-right: 1px solid #aa0000;
	border-left: 1em solid transparent;
	left: -1.5em;
}

.styles_ribbonTailRight__RWuE_ {
	border-left: 1px solid #aa0000;
	border-right: 1em solid transparent;
	right: -1.5em;
}
.styles_ribbonTailLeft__1xv3H,
.styles_ribbonTailRight__RWuE_ {
	z-index: -1;
    width: 1em;
	border-top: 1em solid #aa0000;
	border-bottom: 1em solid #aa0000;
	position: absolute;
	top: 6px;
}

.styles_ribbonText__3mx2q {
	background-color: #ff0000;
	border: 1px solid #aa0000;
	padding: .5em;
	
}
.fonts_fontMain__3VejH {
	font-family: 'Pacifico', cursive;
}
@-webkit-keyframes fonts_rainbow__jYk6L {
	0% {
		color: #ff0000;
	}
	8.333% {
		color: #ff8000;
	}
	16.667% {
		color: #ffff00;
	}
	25.000% {
		color: #80ff00;
	}
	33.333% {
		color: #00ff00;
	}
	41.667% {
		color: #00ff80;
	}
	50.000% {
		color: #00ffff;
	}
	58.333% {
		color: #0080ff;
	}
	66.667% {
		color: #0000ff;
	}
	75.000% {
		color: #8000ff;
	}
	83.333% {
		color: #ff00ff;
	}
	91.667% {
		color: #ff0080;
	}
	100.00% {
		color: #ff0000;
	}
}
@keyframes fonts_rainbow__jYk6L {
	0% {
		color: #ff0000;
	}
	8.333% {
		color: #ff8000;
	}
	16.667% {
		color: #ffff00;
	}
	25.000% {
		color: #80ff00;
	}
	33.333% {
		color: #00ff00;
	}
	41.667% {
		color: #00ff80;
	}
	50.000% {
		color: #00ffff;
	}
	58.333% {
		color: #0080ff;
	}
	66.667% {
		color: #0000ff;
	}
	75.000% {
		color: #8000ff;
	}
	83.333% {
		color: #ff00ff;
	}
	91.667% {
		color: #ff0080;
	}
	100.00% {
		color: #ff0000;
	}
}

.fonts_rainbow__jYk6L {
	-webkit-animation-name: fonts_rainbow__jYk6L;
	        animation-name: fonts_rainbow__jYk6L;
	-webkit-animation-duration: 8s;
	        animation-duration: 8s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

.styles_title__Ndwdk {
	position: absolute;
	left: 0;
	bottom: 0;
	margin: 0;
	padding: 6px;
	box-sizing: border-box;
	font-size: 14px;
	font-family: verdana;
	z-index: 2;
	width: 100%;
	text-overflow: ellipsis;
	text-align: left;
	text-shadow: black 1px 1px 1px;
	color: white;
	background-color: rgba(0, 0, 0, 0.2);
}
.styles_postExcerpt__iAFcJ {
	color: black;
	padding: 1em;
	margin: 0;
	font-family: verdana;
	font-size: 11px;
	text-align: left;
}
.styles_TUNE__1Z-M7 {
	background-color: purple;
	color: white;
}
.styles_POST__2yy3O {
	background-color: white;
	color: black;
	overflow: hidden;
}
.styles_IMAGE__bFyow {
	cursor: pointer;
}
.styles_icon__1AHTt {
	font-size: 80px;
}
.styles_POST__2yy3O,
.styles_IMAGE__bFyow {
	transition: -webkit-transform 100ms;
	transition: transform 100ms;
	transition: transform 100ms, -webkit-transform 100ms;
	-webkit-animation-timing-function: cubic-bezier(0.04, 0.62, 0.57, 1);
	        animation-timing-function: cubic-bezier(0.04, 0.62, 0.57, 1);
}
.styles_POST__2yy3O:hover,
.styles_IMAGE__bFyow:hover {
	-webkit-transform: scale(1.025);
	        transform: scale(1.025);
	z-index: 3;
	box-shadow: 5px 5px currentColor, -5px -5px currentColor,
		5px -5px currentColor, -5px 5px currentColor;
}

@-webkit-keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__outerAnimating {
  -webkit-animation-name: closeWindow;
          animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.styles_content__YoJCc {
	position: relative;
	opacity: 0;
	font-size: 1rem;
	text-align: left;
	font-family: Verdana;
	max-width: 640px;
	display: block;
	margin: auto;
	padding: 1em;
	transition: opacity 600ms, -webkit-transform 600ms;
	transition: opacity 600ms, transform 600ms;
	transition: opacity 600ms, transform 600ms, -webkit-transform 600ms;
	-webkit-transform: translate3d(-20px, 0, 0);
	        transform: translate3d(-20px, 0, 0);
	transition-delay: 300ms;
	background-color: rgba(0, 0, 0, 0.7);
	overflow: visible;
	transition-timing-function: ease-in-out;
}
.styles_inviewFade__2N1h_ {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
}

.styles_parallax__uplz4 {
	/* Set a specific height */
	min-height: 320px;
	height: 75vh;

	/* Create the parallax scrolling effect */
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.styles_parallaxTitle__zmD7p {
	position: absolute;
	font-size: 2rem;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0.1em;
}
.styles_parallaxCredits__ofi5w {
	margin-top: -0.4em;
	margin-bottom: 0.8em;
	font-size: 0.4em;
	opacity: 0.3;
	font-family: Verdana;
}

@-webkit-keyframes homepage_background-gradient__2uYUZ {
	0% {
		background-position: 0% 11%;
	}
	50% {
		background-position: 100% 90%;
	}
	100% {
		background-position: 0% 11%;
	}
}

@keyframes homepage_background-gradient__2uYUZ {
	0% {
		background-position: 0% 11%;
	}
	50% {
		background-position: 100% 90%;
	}
	100% {
		background-position: 0% 11%;
	}
}

.homepage_gradientAnim__2nRmf {
	background-image: linear-gradient(135deg, #fccf31 10%, #f55555 100%);
	background-size: 400% 400%;
	padding-top: 20px;
	-webkit-animation: homepage_background-gradient__2uYUZ 12s ease infinite;
	        animation: homepage_background-gradient__2uYUZ 12s ease infinite;
}

.homepage_homepage__aMNBI {
	text-align: center;
	min-height: 100vh;
}

.homepage_logoWrapper__s8eHN {
	height: 20vh;
	width: 20vh;
	font-size: 0;
	border-radius: 999px;
	border: 1px dashed black;
	overflow: hidden;
	display: inline-block;
}
.homepage_AppLogo__3rMK0 {
	width: 100%;
	height: 100%;
	border-radius: 999px;
}
@-webkit-keyframes homepage_loaded__3Mv7U {
	from {
		-webkit-transform: rotate(-7deg);
		        transform: rotate(-7deg);
		opacity: 0;
	}
	to {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
		opacity: 1;
	}
}
@keyframes homepage_loaded__3Mv7U {
	from {
		-webkit-transform: rotate(-7deg);
		        transform: rotate(-7deg);
		opacity: 0;
	}
	to {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
		opacity: 1;
	}
}
.homepage_loaded__3Mv7U {
	-webkit-animation-name: homepage_loaded__3Mv7U;
	        animation-name: homepage_loaded__3Mv7U;
	-webkit-animation-duration: 400ms;
	        animation-duration: 400ms;
	-webkit-animation-delay: 200ms;
	        animation-delay: 200ms;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
}

.homepage_AppTitleSubheading__3W7ig {
	margin-top: 0;
	margin-bottom: 20vh;
	text-shadow: 1px 1px black;
	font-size: 24px;
}

.homepage_AppTitle__P56BF {
	font-size: 190px;
	opacity: 0.9;
	margin: 0;
	text-shadow: 2px 2px black;
}
.homepage_hello__2MldC {
	font-size: 120px;
}

@media screen and (max-width: 800px) {
	.homepage_AppTitle__P56BF {
		font-size: 100px;
	}
}
.homepage_contentWrap__2ZQud {
	max-width: 960px;
	margin: auto;
}
/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
	.homepage_AppTitle__P56BF {
		font-size: 90px;
	}
}
.homepage_AppTitle__P56BF,
.homepage_AppTitleSubheading__3W7ig {
	mix-blend-mode: multiply;
	color: aquamarine;
	line-height: 1em;
}

.homepage_AppIntro__3aU45 {
	font-size: large;
	font-family: Verdana;
	color: aqua;
	mix-blend-mode: multiply;
	text-shadow: 1px 1px black;
	opacity: 0.8;
}

.homepage_TitleLetter__47FJM {
	margin-right: 0.2em;
	font-size: 1.75em;
	height: 0.5em;
	display: inline-block;
}
@-webkit-keyframes homepage_wobble__1rDgZ {
	from {
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
	to {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}
}
@keyframes homepage_wobble__1rDgZ {
	from {
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
	to {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}
}
.homepage_inviewWobble__1RBfw {
	-webkit-transform-origin: left;
	        transform-origin: left;
	-webkit-animation-name: homepage_wobble__1rDgZ;
	        animation-name: homepage_wobble__1rDgZ;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
}
.homepage_recentFooter__1f8ne {
	margin: 2px -3px;
}

.styles_wrapper__2Dbaf {
	margin: 4px;
	display: block;
}
.styles_wrapper__2Dbaf:hover {
	opacity: 0.9;
}

.styles_header__3dqRG {
}

.styles_link__1hztQ {
	position: absolute;
	bottom: 1em;
	right: 1em;
}


.styles_facts__dWNn5 {
	font-weight: bold;
	text-shadow: black 1px 1px 1px; 
	text-transform: uppercase;
	position: relative;
	/* text-align: center; */
}

.styles_factsRainbowBar__2EqYM {
	position:absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	height: 2px;
}

@-webkit-keyframes styles_factsrainbowscroll__3udJ2 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}
}

@keyframes styles_factsrainbowscroll__3udJ2 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}
}

.styles_factsRainbowBarInner__1qWOG {
	width: 200%;
	height: 100%;
	-webkit-animation-name: styles_factsrainbowscroll__3udJ2;
	        animation-name: styles_factsrainbowscroll__3udJ2;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	background: linear-gradient(to right, rgba(255,0,0), rgba(0,255,0), rgba(0,0,255), rgba(255,0,0));
	background-size: 50%;
}

.styles_heading__3TquS {
	text-align: center;
	font-size: 32px;
	margin: 2em;
}
.styles_postContainer__21G2s {
	position: relative;
	max-width: 640px;
	margin: auto;
	margin-top: 3em;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.2);
}

.styles_postContainer__21G2s:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
	-webkit-filter: blur(10px);
	        filter: blur(10px);
	background: inherit;
}

.styles_wrapper__1MUjm {
	margin: auto;
	margin-top: 3em;
}

.styles_notationsContainer__3xm8K {
	-webkit-perspective: 600px;
	        perspective: 600px;
	min-height: 100vh;
}
.styles_great___494o {
	height: 25vw;
	max-height: 160px;
	position: relative;
	z-index: 2;
	pointer-events: none;
	mix-blend-mode: difference;
}
.styles_great___494o > * {
	height: 100%;
	margin-top: -12.5%;
}

.styles_great___494o > *:before {
	left: 0;
}
.styles_great___494o > *:after {
	right: 0;
	-webkit-transform: scaleX(-1);
	        transform: scaleX(-1);
}
.styles_great___494o > *:before,
.styles_great___494o > *:after {
	display: inline-block;
	content: '';
	top: 0;
	background-image: url(https://static.foxxo.co.uk/808112b417385b2f30a408c2fa2149a5a00894c5/media/great.12b6f9e0.png);
	background-size: contain;
	background-repeat: no-repeat;
	height: 100%;
	width: 50%;
}

.styles_tuneContainer__2hsUd {
	background-color: inherit;
}

.styles_tuneContainer__2hsUd svg {
	width: 100%;
}

.styles_notation__ZkBc5 {
	font-size: 25px;
	height: 2em;
	background-color: rgb(217, 219, 212);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
	border-radius: 1px;
	color: black;
	transition: height 300ms, background-color 200ms, -webkit-transform 300ms;
	transition: height 300ms, background-color 200ms, transform 300ms;
	transition: height 300ms, background-color 200ms, transform 300ms, -webkit-transform 300ms;
	overflow: hidden;
}
.styles_notationWrapper__3oKA2 {
	position: relative;
}
.styles_notationWrapper__3oKA2:after,
.styles_notationWrapper__3oKA2:before {
	content: ' ';
	position: absolute;
	left: 0;
	right: 0;
	height: 1.2em;
}
.styles_notationWrapper__3oKA2:after {
	bottom: 0em;
	background: linear-gradient(transparent, white);
}
.styles_notationWrapper__3oKA2:before {
	z-index: 2;
	top: 0em;
	background: linear-gradient(white, transparent);
}
.styles_notation__ZkBc5:hover {
	background-color: rgb(230, 230, 230);
	height: 3em;
}
.styles_selected__3Sq7f {
	background-color: white;
	transition-delay: 200ms;
	height: 90vh;
	overflow: auto;
	margin-bottom: 2px;
}
.styles_selected__3Sq7f:hover {
	height: 90vh;
	background-color: white;
}
.styles_header__umS99 {
	margin: 0.5em;
	margin-bottom: 0;
	text-align: center;
	cursor: pointer;
}
.styles_notes__1irPL {
	margin: 0.5em;
	font-size: 11px;
	margin-bottom: 10em;
	padding-left: 1em;
}
.styles_subtitle__2HhJR {
	text-align: center;
	margin: 0;
	font-size: 11px;
	font-weight: 100;
}
.styles_date__1DdsX {
}

.style_image__2selA {
	width: 80px;
}

@-webkit-keyframes style_keys-appear__3caQe {
	from {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
		opacity: 0;
	}
	to {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
		opacity: 1;
	}
}

@keyframes style_keys-appear__3caQe {
	from {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
		opacity: 0;
	}
	to {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
		opacity: 1;
	}
}
.style_wrapper__37GkW {
	position: absolute;
	right: 1vw;
	bottom: 75px;
	z-index: 5;
}
@-webkit-keyframes style_dance__2_ZdO {
	from {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
	}
	to {
		-webkit-transform: rotate(10deg);
		        transform: rotate(10deg);
	}
}
@keyframes style_dance__2_ZdO {
	from {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
	}
	to {
		-webkit-transform: rotate(10deg);
		        transform: rotate(10deg);
	}
}

.style_dance__2_ZdO {
	-webkit-animation-name: style_dance__2_ZdO;
	        animation-name: style_dance__2_ZdO;
	-webkit-animation-duration: 350ms;
	        animation-duration: 350ms;
	-webkit-animation-direction: alternate;
	        animation-direction: alternate;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation-timing-function: cubic-bezier(0.79, 0.01, 0.1, 0.98);
	        animation-timing-function: cubic-bezier(0.79, 0.01, 0.1, 0.98);
}
.style_chat__J6AY6 {
	font-size: 11px;
	position: absolute;
	right: 85%;
	bottom: 37%;
	left: -200%;
	min-width: 1em;
	min-height: 2em;
	max-width: 320px;
	background-color: white;
	border-radius: 1em;
	padding: 1em 2em;
}
.style_chat__J6AY6:after {
	content: ' ';
	bottom: 2em;
	right: -1.75em;
	position: absolute;
	border-right: 1em solid transparent;
	border-left: 1em solid white;
	border-bottom: 0.75em solid transparent;
	border-top: 1em solid transparent;
}
.style_relative__1N3_Y {
	-webkit-animation-name: style_keys-appear__3caQe;
	        animation-name: style_keys-appear__3caQe;
	-webkit-animation-duration: 450ms;
	        animation-duration: 450ms;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	position: relative;
}

@-webkit-keyframes styles_numane__2roDS {
	from {
		-webkit-transform: translateX(-10vw);
		        transform: translateX(-10vw);
	}
	to {
		-webkit-transform: translateX(120vw);
		        transform: translateX(120vw);
	}
}

@keyframes styles_numane__2roDS {
	from {
		-webkit-transform: translateX(-10vw);
		        transform: translateX(-10vw);
	}
	to {
		-webkit-transform: translateX(120vw);
		        transform: translateX(120vw);
	}
}

.styles_noodle__2fHh9 {
	-webkit-animation-name: styles_numane__2roDS;
	        animation-name: styles_numane__2roDS;
	-webkit-animation-duration: 4000ms;
	        animation-duration: 4000ms;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	position: fixed;
	top: 50vh;
	z-index: 2;
}
.styles_distant__3-XAT {
	-webkit-animation-duration: 8000ms;
	        animation-duration: 8000ms;
	width: 50px;
	height: 50px;
}

