.tuneContainer {
	background-color: inherit;
}

.tuneContainer svg {
	width: 100%;
}

.notation {
	font-size: 25px;
	height: 2em;
	background-color: rgb(217, 219, 212);
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
	border-radius: 1px;
	color: black;
	transition: height 300ms, background-color 200ms, transform 300ms;
	overflow: hidden;
}
.notationWrapper {
	position: relative;
}
.notationWrapper:after,
.notationWrapper:before {
	content: ' ';
	position: absolute;
	left: 0;
	right: 0;
	height: 1.2em;
}
.notationWrapper:after {
	bottom: 0em;
	background: linear-gradient(transparent, white);
}
.notationWrapper:before {
	z-index: 2;
	top: 0em;
	background: linear-gradient(white, transparent);
}
.notation:hover {
	background-color: rgb(230, 230, 230);
	height: 3em;
}
.selected {
	background-color: white;
	transition-delay: 200ms;
	height: 90vh;
	overflow: auto;
	margin-bottom: 2px;
}
.selected:hover {
	height: 90vh;
	background-color: white;
}
.header {
	margin: 0.5em;
	margin-bottom: 0;
	text-align: center;
	cursor: pointer;
	composes: fontMain from '.../../styles/fonts.css';
}
.notes {
	margin: 0.5em;
	font-size: 11px;
	margin-bottom: 10em;
	padding-left: 1em;
}
.subtitle {
	text-align: center;
	margin: 0;
	font-size: 11px;
	font-weight: 100;
}
.date {
}
