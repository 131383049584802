/* body {
	background-color: white;
} */

.App {
	color: white;
	position: relative;
	padding-bottom: 10em;

	z-index: 3;
	position: relative;
	min-height: 100vh;
}
.footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.1);
	border-top: 1px dashed rgba(0, 0, 0, 0.2);
	color: rgba(255, 255, 255, 0.4);
	padding: 2em;
	display: flex;
	justify-content: space-around;
}
.footer > * {
	font-size: 10px;
}
.sea {
	z-index: -2;
	position: absolute;
	background: linear-gradient(transparent, #e66465, #9198e5);
	left: 0;
	right: 0;
	bottom: 0;
	height: 50vh;
}
.love {
	width: 10em;
	padding: 1em;
	text-align: center;
}

@keyframes cloud {
	from {
		transform: translateX(-2vw);
	}
	to {
		transform: translateX(2vw);
	}
}

.cloud {
	animation-name: cloud;
	animation-duration: 9s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	z-index: 2;
	position: fixed;
	left: 5vw;
	top: 13vh;
}

.blocks {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 246px;
	background-image: url('./blox.png');
	background-size: 831px;
	z-index: -1;
	background-repeat-x: repeat;
	bottom: 0;
	background-position-x: center;
}
