.heading {
	composes: fontMain from 'styles/fonts.css';
	text-align: center;
	font-size: 32px;
	margin: 2em;
}
.postContainer {
	position: relative;
	max-width: 640px;
	margin: auto;
	margin-top: 3em;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.2);
}

.postContainer:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
	filter: blur(10px);
	background: inherit;
}
