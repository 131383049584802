.title {
	position: absolute;
	left: 0;
	bottom: 0;
	margin: 0;
	padding: 6px;
	box-sizing: border-box;
	font-size: 14px;
	font-family: verdana;
	z-index: 2;
	width: 100%;
	text-overflow: ellipsis;
	text-align: left;
	text-shadow: black 1px 1px 1px;
	color: white;
	background-color: rgba(0, 0, 0, 0.2);
}
.postExcerpt {
	color: black;
	padding: 1em;
	margin: 0;
	font-family: verdana;
	font-size: 11px;
	text-align: left;
}
.TUNE {
	background-color: purple;
	color: white;
}
.POST {
	background-color: white;
	color: black;
	overflow: hidden;
}
.IMAGE {
	cursor: pointer;
}
.icon {
	font-size: 80px;
}
.POST,
.IMAGE {
	transition: transform 100ms;
	animation-timing-function: cubic-bezier(0.04, 0.62, 0.57, 1);
	composes: rainbow from "../../styles/fonts.css";
}
.POST:hover,
.IMAGE:hover {
	transform: scale(1.025);
	z-index: 3;
	box-shadow: 5px 5px currentColor, -5px -5px currentColor,
		5px -5px currentColor, -5px 5px currentColor;
}
