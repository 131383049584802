.image {
	width: 80px;
}

@keyframes keys-appear {
	from {
		transform: rotate(-10deg);
		opacity: 0;
	}
	to {
		transform: rotate(0);
		opacity: 1;
	}
}
.wrapper {
	position: absolute;
	right: 1vw;
	bottom: 75px;
	z-index: 5;
}
@keyframes dance {
	from {
		transform: rotate(-10deg);
	}
	to {
		transform: rotate(10deg);
	}
}

.dance {
	animation-name: dance;
	animation-duration: 350ms;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.79, 0.01, 0.1, 0.98);
}
.chat {
	font-size: 11px;
	position: absolute;
	right: 85%;
	bottom: 37%;
	left: -200%;
	min-width: 1em;
	min-height: 2em;
	max-width: 320px;
	background-color: white;
	border-radius: 1em;
	padding: 1em 2em;
}
.chat:after {
	content: ' ';
	bottom: 2em;
	right: -1.75em;
	position: absolute;
	border-right: 1em solid transparent;
	border-left: 1em solid white;
	border-bottom: 0.75em solid transparent;
	border-top: 1em solid transparent;
}
.relative {
	animation-name: keys-appear;
	animation-duration: 450ms;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	position: relative;
}
