.parallax {
	/* Set a specific height */
	min-height: 320px;
	height: 75vh;

	/* Create the parallax scrolling effect */
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.parallaxTitle {
	position: absolute;
	font-size: 2rem;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0.1em;
}
.parallaxCredits {
	margin-top: -0.4em;
	margin-bottom: 0.8em;
	font-size: 0.4em;
	opacity: 0.3;
	font-family: Verdana;
}
