.wrapper {
	margin: 4px;
	display: block;
}
.wrapper:hover {
	opacity: 0.9;
}

.header {
}

.link {
	position: absolute;
	bottom: 1em;
	right: 1em;
}
