@keyframes numane {
	from {
		transform: translateX(-10vw);
	}
	to {
		transform: translateX(120vw);
	}
}

.noodle {
	animation-name: numane;
	animation-duration: 4000ms;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	animation-timing-function: linear;
	position: fixed;
	top: 50vh;
	z-index: 2;
}
.distant {
	animation-duration: 8000ms;
	width: 50px;
	height: 50px;
}
