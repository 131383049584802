.ribbon {
	z-index: 2;
	position: absolute;
	font-family: Verdana;
	font-size: .7rem;
	right:0;
	top: 0;
    transform: translateX(33%) translateY(0.75em) rotate(45deg);
	text-shadow: black 1px 1px;
}
.ribbonInner {
	position: relative;
}
.ribbonTailLeft {
	border-right: 1px solid #aa0000;
	border-left: 1em solid transparent;
	left: -1.5em;
}

.ribbonTailRight {
	border-left: 1px solid #aa0000;
	border-right: 1em solid transparent;
	right: -1.5em;
}
.ribbonTailLeft,
.ribbonTailRight {
	z-index: -1;
    width: 1em;
	border-top: 1em solid #aa0000;
	border-bottom: 1em solid #aa0000;
	position: absolute;
	top: 6px;
}

.ribbonText {
	background-color: #ff0000;
	border: 1px solid #aa0000;
	padding: .5em;
	
}